@use 'base/variables' as v;
@use 'base/mixins' as m;


.accordion-ocultar {
    display: none;
}

.accordion-mostrar {
    display: block;
}

.accordion_bitacora,
.accordion_realizados {
    cursor: pointer;
    user-select: none;
    width: fit-content;
}

.accordion_bitacora.active .arrow {
    transform: rotate(180deg);
}

.accordion_realizados .arrow{
    transform: rotate(0deg);
}


.accordion_realizados.active .arrow {
    transform: rotate(180deg);
}