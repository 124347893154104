@use 'base/variables' as v;

.not_found {

    h3, .link-container a{
        color: v.$azul-oscuro;
    }

    .error-container {
        text-align: center;
        font-size: 180px;
        font-family: 'Catamaran', sans-serif;
        font-weight: 800;
        margin: 20px 15px;
    }

    .error-container>span {
        display: inline-block;
        line-height: 0.7;
        position: relative;
        color: #FFB485;
    }

    .error-container>span {
        display: inline-block;
        position: relative;
        vertical-align: middle;
    }

    .error-container>span:nth-of-type(1) {
        // color: #D1F2A5;
        color: v.$color-barra;
        animation: colordancing 4s infinite;
    }

    .error-container>span:nth-of-type(3) {
        // color: #F56991;
        color: v.$azul-claro;
        animation: colordancing2 4s infinite;
    }

    .error-container>span:nth-of-type(2) {
        width: 120px;
        height: 120px;
        border-radius: 999px;
    }

    .error-container>span:nth-of-type(2):before,
    .error-container>span:nth-of-type(2):after {
        border-radius: 0%;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        border-radius: 999px;
        box-shadow: inset 30px 0 0 rgba(115, 186, 243, 0.4),
            inset 0 30px 0 rgba(14, 164, 223, 0.4),
            inset -30px 0 0 rgba(20, 114, 177, 0.4),
            inset 0 -30px 0 rgba(5, 41, 158, 0.4);
        animation: shadowsdancing 4s infinite;
    }

    .error-container>span:nth-of-type(2):before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .screen-reader-text {
        position: absolute;
        top: -9999em;
        left: -9999em;
    }

}

// color 0
@keyframes shadowsdancing {
    0% {
        box-shadow: inset 30px 0 0 rgba(225, 238, 244, 0.4),
            /* v.$color-principal */
            inset 0 30px 0 rgba(84, 197, 248, 0.4),
            /* v.$azul-claro */
            inset -30px 0 0 rgba(1, 87, 155, 0.4),
            /* v.$azul-oscuro */
            inset 0 -30px 0 rgba(8, 39, 63, 0.4);
        /* v.$color-barra */
    }

    25% {
        box-shadow: inset 30px 0 0 rgba(84, 197, 248, 0.4),
            /* v.$azul-claro */
            inset 0 30px 0 rgba(1, 87, 155, 0.4),
            /* v.$azul-oscuro */
            inset -30px 0 0 rgba(8, 39, 63, 0.4),
            /* v.$color-barra */
            inset 0 -30px 0 rgba(225, 238, 244, 0.4);
        /* v.$color-principal */
    }

    50% {
        box-shadow: inset 30px 0 0 rgba(1, 87, 155, 0.4),
            /* v.$azul-oscuro */
            inset 0 30px 0 rgba(8, 39, 63, 0.4),
            /* v.$color-barra */
            inset -30px 0 0 rgba(225, 238, 244, 0.4),
            /* v.$color-principal */
            inset 0 -30px 0 rgba(84, 197, 248, 0.4);
        /* v.$azul-claro */
    }

    75% {
        box-shadow: inset 30px 0 0 rgba(8, 39, 63, 0.4),
            /* v.$color-barra */
            inset 0 30px 0 rgba(225, 238, 244, 0.4),
            /* v.$color-principal */
            inset -30px 0 0 rgba(84, 197, 248, 0.4),
            /* v.$azul-claro */
            inset 0 -30px 0 rgba(1, 87, 155, 0.4);
        /* v.$azul-oscuro */
    }

    100% {
        box-shadow: inset 30px 0 0 rgba(225, 238, 244, 0.4),
            /* v.$color-principal */
            inset 0 30px 0 rgba(84, 197, 248, 0.4),
            /* v.$azul-claro */
            inset -30px 0 0 rgba(1, 87, 155, 0.4),
            /* v.$azul-oscuro */
            inset 0 -30px 0 rgba(8, 39, 63, 0.4);
        /* v.$color-barra */
    }
}

@keyframes colordancing {
    0% {
        color: v.$color-principal;
    }

    25% {
        color: v.$azul-claro;
    }

    50% {
        color: v.$azul-oscuro;
    }

    75% {
        color: v.$color-barra;
    }

    100% {
        color: v.$color-principal;
    }
}

@keyframes colordancing2 {
    0% {
        color: v.$azul-claro;
    }

    25% {
        color: v.$azul-oscuro;
    }

    50% {
        color: v.$color-barra;
    }

    75% {
        color: v.$color-principal;
    }

    100% {
        color: v.$azul-claro;
    }
}