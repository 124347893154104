@use 'base/variables' as v;


.toggle-accordion{
    width: fit-content;
    margin: 0;
    margin-bottom: 1rem;

    &:hover{
        cursor: pointer;
    }

}
/* Estilos para el contenedor y la animación */
.toggle-accordion-cont{
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    opacity: 0;
}

/* Cuando el contenedor está expandido */
.toggle-accordion-cont.expanded {
    max-height: 1000px;
    opacity: 1;
}


/* Flecha rotada cuando está expandido */
.toggle-accordion.expanded .arrow{
    transform: rotate(180deg);
}