@use 'base/variables' as v;
@use 'base/mixins' as m;


.altas_secciones {

    section,
    .mostrar {
        height: 100%;
        justify-content: space-between;
    }

    .form-group {
        width: 100%;
    }

    .dropzone {
        grid-column: 1/3;
        border: 4px dashed v.$color-line;
        height: 11rem
    }

    .empleado_formP1 {
        @include m.grid(3, 2rem);
        margin: 2rem 0;

        div:first-child {
            grid-column: 1/3;
        }
    }

    .empleado_formP2 {
        @include m.grid(3, 2rem);
        margin: 2rem 0;
    }

    .empleado_formP3 {
        @include m.grid(2, 2rem);
        margin: 2rem 0;
    }

    .empleado-form1C {
        @include m.grid(2, 2rem);
        margin: 2rem 0;
    }

    .empleado-form2C {
        @include m.grid(2, 2rem);
        margin: 2rem 0;
    }

    .empleado-form3C {
        @include m.grid(3, 2rem);
        margin: 2rem 0;
    }

    .empleado-form13C {
        @include m.grid(3, 2rem);
        margin: 2rem 0;

        .empleado_form-depa {
            grid-column: 1/3;
        }

    }

}

.empleado_botones {
    @include m.grid(4, 1rem);
    margin: 1.5rem 0;
}

.seccion_foto {


    .cont_perfil {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 11rem;

    }
}

.empleado {
    // .screen {
    //     display: none;
    // }

    // .screen.active {
    //     display: block;
    // }

    // .tab {

    //     .circle-check,
    //     .active {
    //         color: #01579b;
    //         font-weight: 700;
    //     }

    //     .active::before {
    //         color: #01579b;
    //         content: "\f192";
    //         font-family: Font Awesome\ 5 Free;
    //         margin-right: 1rem;
    //         font-weight: 700;
    //     }

    // }

    .grid-altas {

        #myGrid2,
        #myGrid3 {
            padding: 1rem 0 0 0;
            height: 24rem;
        }
    }

    .quickFilterContent {
        align-items: end;

        .form-group {
            width: 60%;
        }

        .contenedor_totales {
            border: 2px solid #dbdbdb;
            border-radius: 8px;

            div {
                padding: 8px;
            }

            p:nth-child(1){
                color: v.$negro;
                font-weight: 600;
            }

            p:nth-child(1),
            p:nth-child(3) {
                font-size: 0.9em;
                color: v.$gris-disable;
                letter-spacing: 1px;
            }

            p:nth-child(2) {
                font-weight: 600;
                font-size: 1.7em;
                color: v.$azul-oscuro;
            }

        }

    }
}