@use 'base/variables' as v;
@use 'base/mixins' as m;

.screen {
    display: none;
}

.screen.active {
    display: block;
}

.tab {

    .circle-check,
    .active {
        color: #01579b;
        font-weight: 700;
    }

    .active::before {
        color: #01579b;
        content: "\f192";
        font-family: Font Awesome\ 5 Free;
        margin-right: 1rem;
        font-weight: 700;
    }

}