@use 'base/variables' as v;

input,
select,
textarea {
    outline: none;
    padding-left: 8px;
    border-radius: 10px;
    border: 2px solid rgb(219, 219, 219);
    background: v.$blanco;
    color: v.$negro;
    width: 100%;
    height: 2.8rem;
    font-size: 14px;


    &:focus {
        border: 2px solid #2593ea;
        box-shadow: 0px 0px 12px 2px #0080ff29;
    }
}

.form-group>listado-srch:first-of-type {
    display: flex;
    align-items: center;
    outline: none;
    padding-left: 8px;
    border-radius: 10px;
    border: 2px solid rgb(219, 219, 219);
    background: v.$blanco;
    color: v.$negro;
    width: 100%;
    height: 2.8rem;
    font-size: 14px;

    &:before {
        content: '\f107';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 20px;
        height: inherit;
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        font-size: 12px;
        padding: 5px;
    }

}

.form-group label {
    position: absolute;
    top: 8px;
    left: 8px;
    pointer-events: none;
    font-size: 16px;
    color: v.$color-nav;
}


.form-group {
    width: auto;
    position: relative;

    select+label,
    listado-srch+label {
        top: -15px;
        left: 5px;
        font-size: 12px;
        color: #333;
    }
}

.form-group input:focus+label,
.form-group input:not(:placeholder-shown)+label {
    transition: 0.1s ease-in-out;
    transition-delay: 0.3s;
    top: -15px;
    left: 0px;
    font-size: 12px;
    color: #333;
}

.form-group textarea:focus+label,
.form-group textarea:not(:placeholder-shown)+label {
    transition: 0.1s ease-in-out;
    transition-delay: 0.3s;
    top: -15px;
    left: 0px;
    font-size: 12px;
    color: #333;
}

.app .form-group input[type=file] {
    padding: 3px 10px;
}


.input-disabled {
    color: #9d9d9d !important;
    font-weight: bold;
    pointer-events: none;
}

// Exclusivo LOGIN 
.labelImportant::after {
    content: " *";
    color: v.$rojo-fuerte;
    font-weight: bold;
    position: relative;
    top: -5px;
}

.form-group:focus-within label {
    top: -15px;
    left: 0px;
    font-size: 12px;
    color: #333;
}

.grid-col-2_4 {
    grid-column: 2/4;
}

.grid-col-1_3 {
    grid-column: 1/3
}

.grid-col-3_4 {
    grid-column: 3/4
}


.ag-body .ag-body-vertical-scroll {
    position: absolute;
    right: 0;
}