#custom-spinner{
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 10;
    background-color: #00000066;

    .dot:before {
        background-color: #fff;
    }
}