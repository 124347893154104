@use "base/variables" as v;
@use "base/mixins" as m;

// #aside-contenedor {
//     position: fixed;
//     inset: 0;
//     z-index: 2;

// .gasto-fondo {
// background-color: #000000a1;
//     position: absolute;
//     inset: 0;
// }

// .aside {
//     position: absolute;
//     background-color: v.$blanco;
//     min-height: 100%;
//     width: 100%;
//     right: 0;
//     padding: 1rem;
//     // transform: translate(100%);
//     transform: translate(0%);
//     max-width: 60%;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;

//     &_encabezado {
//         flex: initial;
//     }

//     &_tabla {
//         flex: auto;
//         position: relative;

//         &-cont {
//             position: absolute;
//             inset: 0;

//             .grid-altas {
//                 height: 80%;

//                 #myGridDetGasto {
//                     height: 100%;
//                 }
//             }
//         }


//     }

//     &_button {
//         flex: initial;
//     }

//     @include m.tablet {
//         max-width: 100%;
//     }
// }

// }

#aside-contenedor {
    inset: 0;
    position: fixed;
    z-index: 2;
    background: rgba(0, 0, 0, .631);

    .gasto-fondo {
        background-color: v.$blanco;
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 60%;
        min-height: 100%;
        transform: translate(0%);

        @include m.tablet {
            width: 100%;
        }

        .aside_contenido {
            height: calc(100% - 150px);
            position: absolute;
            left: 0;
            right: 0;
            overflow-y: auto;
            word-break: break-word;
        }

        .quickFilterContent .form-dsg {
            margin-left: 0;
            input {
                
                @include m.desktop {
                    width: 30rem;
                }

                @include m.tablet {
                    width: auto;
                }
            }
        }
    }

}

#aside-contenedor.asideAnimation {
    display: block !important;

    .gasto-fondo {
        transform: translate(0%);
        animation: letterSpacingAnimation 1s forwards;
    }
}

.gridRespon {
    display: grid;
    row-gap: 2rem;
    column-gap: 1.3rem;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    margin-bottom: 3rem;
}