@use "base/variables" as v;
@use "base/mixins" as m;

#pax-contenedor {
  position: fixed;
  inset: 0;
  z-index: 2;

  .pax-fondo {
    background-color: #000000a1;
    position: absolute;
    inset: 0;
  }

  .pax-form {
    position: absolute;
    background-color: v.$blanco;
    max-width: 40rem;
    min-height: 100%;
    width: 100%;
    right: 0;
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translate(100%);
  }
}

#contenedor-template {
  #pax_formAltas {
    position: absolute;
    right: 0;
    left: 0;
    height: -webkit-calc(100% - 200px);
    height: calc(100% - 200px);
    overflow-y: auto;
    word-wrap: break-word;
  }

  .cont-form2 {
    @include m.grid(2, 1rem);
    margin: 2rem 0;
  }

  .cont-form3 {
    @include m.grid(3, 1rem);
    margin: 2rem 0;
  }
}

#pax-contenedor.formPasajeros {
  display: block !important;

  .pax-form {
    transform: translate(0%);
    animation: letterSpacingAnimation 1s forwards;
  }
}

@keyframes letterSpacingAnimation {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
