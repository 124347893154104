@use "base/variables" as v;
@use "base/mixins" as m;

#programacion_vuelos{

    .form-dsg {
        margin: 0 0 0.5rem 2rem;

    }

}