@use 'base/variables' as v;

.ag-theme-balham {
    input {
        border-radius: 0;
    }
}

.gridInputFile {

    input[type=file] {
        visibility: hidden;
        padding: 0;
    }

    input[type=file]::file-selector-button {
        visibility: visible;
        min-width: 8rem;
    }

}

.myGridSinFondo {

    .ag-root-wrapper {
        border: none;
    }

    .ag-header,
    .ag-advanced-filter-header {
        background-color: transparent;
        border-bottom: none;
    }
}

// Iconos
.ag-icon-tree-closed,
.ag-icon-tree-open {
    color: v.$color-nav;
}

// subtablas
// .ag-details-row {
//     // padding: 0;
// }

// Ocultar 3 puntos de la tablas
.ag-header-cell-menu-button {
    display: none;
}
.ag-details-grid .ag-body {
    width: 99.9%;
}
.ag-opacity-zero{
    display: none;
}