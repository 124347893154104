@use 'base/variables' as v;

#contenedor-docs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.documento {

    background-color: v.$blanco;
    border-radius: 10px;
    box-shadow: 0 2px 2px 0 rgba(120, 217, 255, .486), 0 3px 1px -2px rgba(4, 59, 161, .12), 0 1px 5px 0 rgba(0, 0, 0, .2);

    & .documento-cont {
        /* width: 25rem; */
        padding: 10px 14px;

        & .file-type {
            font-size: 30px;
            color: #636363;
        }

        & .file-text {
            flex-direction: column;
            gap: 2px;

            a {
                color: v.$negro;
            }

            p {
                font-size: 13px;
                color: v.$color-line;
            }
        }
    }
}

.app .pasajeros {
    .form-group input[type=file] {
        padding: 6px 10px;
    }
}

.file-del {
    align-items: center;
    justify-content: flex-end;

    & i {
        /* background-color: #5fbaffa3; */
        padding: 4px 6px;
        border-radius: 12px;
        transition: transform 0.3s ease;

        &:hover {
            background-color: v.$rojo-fuerte;
            transform: rotate(180deg);
            color: #fff;
        }

        &:active {
            background-color: v.$rojo-claro;
        }
    }

}

.documento-pass1 {
    .file-del {
        position: relative;
        top: -8px;
        left: -8px;
    }
}