@use 'base/variables' as v;

.alertas {
    margin-top: 2rem;
    display: grid;
    justify-content: center;
    gap: 5px;
}

.alerta{
    width: 22rem;

    &_error{
        background: #faeded;
        padding: 5px 15px;
        border: 1px solid #ff0005;
        border-radius: 5px;
        border-right: 5px solid #ff0005;
    }
}


.campoRequerido{
    border: 2px solid #ff0005 !important;
    box-shadow: 0px 0px 12px 2px #ff000524;
}